import React from 'react';
import Bridge from './components/Bridge';
import './styles.css';

function App() {
  return (
    <div>
      <Bridge />
    </div>

  );
}

export default App;