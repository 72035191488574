import React, { useState, useEffect } from 'react';
import { init, useConnectWallet, useSetChain } from '@web3-onboard/react';
import injectedModule, { ProviderLabel } from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect';
import trustModule from '@web3-onboard/trust'
import metamaskSDK from '@web3-onboard/metamask'
import { ethers } from 'ethers';

// contracts
const PRESALE_CONTRACT_ADDRESS = '0x5A279924601be2BbA519469Cc99Dd446B56a6bEb';
const USDT_CONTRACT_ADDRESS = '0x55d398326f99059fF775485246999027B3197955';
const TOKEN_CONTRACT_ADDRESS = '0xD10d6F1d4b905b67735a62079743723E6f3C19C3';

const trust = trustModule({
  displayUnavailable: true,
  deepLink: 'https://link.trustwallet.com/open_url?coin_id=60&url=https://presale.bulldogito.com/'
});

const metamaskSDKWallet = metamaskSDK({options: {
  extensionOnly: false,
  dappMetadata: {
    name: 'BULLDOGITO - Presale',
    url: 'http://presale.bulldogito.com/',
    deepLink: 'metamask://',
  }
}});

const injected = injectedModule();

const walletConnect = walletConnectModule({
  projectId: 'bb9b8aaee29de78b88fd7ada9cc6967d',
  requiredChains: [56],
  dappUrl: 'http://presale.bulldogito.com/'
});

const onboard = init({
  wallets: [metamaskSDKWallet, trust, injected, walletConnect],
  chains: [
    {
      id: '0x38',
      token: 'BNB',
      label: 'BNB Smart Chain',
      rpcUrl: 'https://bsc-dataseed.binance.org/'
    }
  ],
  appMetadata: {
    name: 'BULLDOGITO - Presale',
    icon: '<svg style="transform-origin: 50% 50%; max-height: none;"><img style="height: 100%;" src="https://presale.bulldogito.com/images/fevicon.png"></svg>',
    description: 'A presale DApp for token swap',
  }
});


function Bridge() {
  const [{ wallet, connecting }, connect] = useConnectWallet();
  const [{ connectedChain }, setChain] = useSetChain();
  const [usdtAmount, setUsdtAmount] = useState('');
  const [tokenAmount, setTokenAmount] = useState('');
  const [usdtBalance, setUsdtBalance] = useState('0');
  const [tokenBalance, setTokenBalance] = useState('0');
  const [isApproved, setIsApproved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [currentRate, setCurrentRate] = useState(null);
  const [currentPhase, setCurrentPhase] = useState(null);

  // Referrer address state
  const [referrer, setReferrer] = useState(null);

  useEffect(() => {
    // Check for referrer in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get('ref');
    if (ref) {
      setReferrer(`0x${ref}`);
    }

    const fetchBalancesAndApproval = async () => {
      if (wallet) {
        const provider = new ethers.BrowserProvider(wallet.provider);
        const signer = await provider.getSigner();

        const usdtContract = new ethers.Contract(USDT_CONTRACT_ADDRESS, [
          "function balanceOf(address owner) view returns (uint256)",
          "function allowance(address owner, address spender) view returns (uint256)"
        ], signer);

        const tokenContract = new ethers.Contract(TOKEN_CONTRACT_ADDRESS, [
          "function balanceOf(address owner) view returns (uint256)"
        ], signer);

        try {
          const usdtBalance = await usdtContract.balanceOf(wallet.accounts[0].address);
          setUsdtBalance(parseFloat(ethers.formatUnits(usdtBalance, 18)).toFixed(2));

          const tokenBalance = await tokenContract.balanceOf(wallet.accounts[0].address);
          setTokenBalance(parseFloat(ethers.formatUnits(tokenBalance, 18)).toFixed(0));

          const allowance = await usdtContract.allowance(wallet.accounts[0].address, PRESALE_CONTRACT_ADDRESS);
          setIsApproved(parseInt(allowance.toString()) >= parseInt(ethers.parseUnits(usdtAmount || '0', 18).toString()));
        } catch (error) {
          setMessage('Error fetching balances or approval');
          console.error('Error fetching balances or approval:', error);
        }
      }
    };

    fetchBalancesAndApproval();
  }, [wallet, usdtAmount]);

  const showMessage = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage('');
    }, 7000);
  };

  const handleConnectWallet = async () => {
    const connectedWallets = await connect();
    if (connectedWallets.length > 0) {
      const walletChainId = connectedWallets[0].chains[0].id;
      if (walletChainId !== '0x38') {
        await setChain({ chainId: '0x38' });
      }
    }
  };

  const handleApprove = async () => {
    if (!wallet) return;

    setLoading(true);

    try {
      const provider = new ethers.BrowserProvider(wallet.provider);
      const signer = await provider.getSigner();

      const usdtContract = new ethers.Contract(USDT_CONTRACT_ADDRESS, [
        "function approve(address spender, uint256 amount) public returns (bool)"
      ], signer);

      const usdtAmountInWei = ethers.parseUnits(usdtAmount, 18);
      const approveTx = await usdtContract.approve(PRESALE_CONTRACT_ADDRESS, usdtAmountInWei);
      await approveTx.wait();

      setIsApproved(true);
      showMessage('USDT approved for presale contract.');
    } catch (error) {
      const errorMessage = error.code === 'ACTION_REJECTED' ? 'User rejected the transaction.' : `Error approving USDT: ${error.message}`;
      showMessage(errorMessage);
      console.error('Error approving USDT:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSwapTokens = async () => {
    if (!wallet || !isApproved || isSwapDisabled) return;

    setLoading(true);

    try {
      const provider = new ethers.BrowserProvider(wallet.provider);
      const signer = await provider.getSigner();

      const presaleContract = new ethers.Contract(PRESALE_CONTRACT_ADDRESS, [
        "function buyTokens(uint256 usdtAmount, address referrer) public"
      ], signer);

      const usdtAmountInWei = ethers.parseUnits(usdtAmount, 18);
      const tx = await presaleContract.buyTokens(usdtAmountInWei, referrer || ethers.ZeroAddress);
      await tx.wait();

      showMessage('Tokens purchased successfully!');
      
      const tokenContract = new ethers.Contract(TOKEN_CONTRACT_ADDRESS, [
        "function balanceOf(address owner) view returns (uint256)"
      ], signer);
      const updatedTokenBalance = await tokenContract.balanceOf(wallet.accounts[0].address);
      setTokenBalance(parseFloat(ethers.formatUnits(updatedTokenBalance, 18)).toFixed(0));

      const usdtContract = new ethers.Contract(USDT_CONTRACT_ADDRESS, [
        "function balanceOf(address owner) view returns (uint256)"
      ], signer);
      const updatedUsdtBalance = await usdtContract.balanceOf(wallet.accounts[0].address);
      setUsdtBalance(parseFloat(ethers.formatUnits(updatedUsdtBalance, 18)).toFixed(2));
    } catch (error) {
        let errorMessage = '';

        if (error.code === 'ACTION_REJECTED') {
            errorMessage = 'User rejected the transaction.';
        } else if (error.reason && error.reason.includes("Pausable: paused")) {
            errorMessage = 'The contract is currently paused. Please try again later.';
        } else {
            errorMessage = `Error during token purchase: ${error.message || 'Unknown error'}`;
        }

        showMessage(errorMessage);
        console.error('Error during token purchase:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPhaseAndRate = async (signerOrProvider) => {
      try {
          const presaleContract = new ethers.Contract(PRESALE_CONTRACT_ADDRESS, [
              "function phase() view returns (uint8)",
              "function rate() view returns (uint256)"
          ], signerOrProvider);

          const fetchedPhase = await presaleContract.phase();
          const fetchedRate = await presaleContract.rate();

          setCurrentPhase(fetchedPhase);
          setCurrentRate(fetchedRate);
      } catch (error) {
          console.error('Error fetching current phase and rate:', error);
      }
  };

  // Tenta buscar a fase e taxa ao carregar a página usando o provedor público
  useEffect(() => {
      const provider = new ethers.getDefaultProvider('https://bsc-dataseed.binance.org/');
      fetchPhaseAndRate(provider);
  }, []);

  // Tenta buscar a fase e taxa ao conectar a carteira, se a consulta inicial falhar
  useEffect(() => {
      if (wallet && (currentPhase === null || currentRate === null)) {
          const signer = new ethers.BrowserProvider(wallet.provider).getSigner();
          fetchPhaseAndRate(signer);
      }
  }, [wallet]);

  const handleUsdtAmountChange = (e) => {
    let value = e.target.value;
    value = value.replace(',', '.');

    const isValidInput = /^\d*\.?\d{0,2}$/.test(value);

    if (isValidInput) {
        setUsdtAmount(value);

        // Calcula os tokens apenas se o valor atual for válido e não estiver vazio
        if (value && !isNaN(value) && currentRate !== null) {
            const calculatedTokens = value / ethers.formatUnits(currentRate, 18);
            setTokenAmount(calculatedTokens.toFixed(0));
        } else {
            setTokenAmount('0');
        }
    }
  };


  const handleCopyLink = () => {
    if (!wallet) {
      showMessage('Please connect your wallet first.');
      return;
    }

    const addressWithoutPrefix = wallet.accounts[0].address.replace(/^0x/, '');
    const refLink = `${window.location.origin}/?ref=${addressWithoutPrefix}`;
    
    navigator.clipboard.writeText(refLink)
      .then(() => showMessage('Referral link copied!'))
      .catch(err => console.error('Error copying link: ', err));
  };

  const isSwapDisabled = usdtAmount === '' || parseFloat(usdtAmount) < 1 || parseFloat(usdtAmount) > parseFloat(usdtBalance);

  return (
    <section className="lg:py-20 sm:py-14 py-10" id="bridge">
      <div className="container">
        <div className="text-center 2xl:mb-24 lg:mb-16 mb-[30px]">
          <h2 className="text-secondary xl:text-5xl md:text-4xl text-3xl font-bold xl:leading-tight md:leading-snug leading-snug capitalize mb-4">
            BULLDOGITO - PRESALE 
            {currentRate === null ? ' (Loading price...)' : ` (Price - ${ethers.formatUnits(currentRate, 18)} USD)`}
          </h2>
        </div>
        <div className="flex flex-wrap justify-center">
          <div className="lg:w-5/12 w-full lg:mb-0 mb-7">
            <img src={`${process.env.PUBLIC_URL}/images/bridge.png`} className="lg:w-auto w-2/4 mx-auto" alt="Bridge" />
          </div>
          <div className="lg:w-7/12 w-full">
            <div className="max-w-[820px] bg-white md:p-8 p-4 md:rounded-[20px] rounded-xl border-[6px] border-secondary">
              <div className="flex flex-col md:gap-6 gap-4">
                <div className="sm:p-4 p-3 sm:rounded-2xl rounded-lg flex flex-col sm:flex-row gap-2 sm:items-center justify-between bg-[#86d7fe66]">
                  <div>
                    <input
                      type="text"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      className="w-full bg-transparent xl:text-3xl sm:text-xl placeholder:text-black focus-visible:outline-0"
                      placeholder="0"
                      value={usdtAmount}
                      onChange={handleUsdtAmountChange}
                    />
                  </div>
                  <div>
                    <div className="group relative bg-[#FA9B27] md:px-6 px-4 md:py-4 py-2 rounded-[10px] sm:mb-4 mb-3">
                      <span
                        className="w-full text-[20px] xl:text-2xl tracking-[1.3px] leading-none uppercase text-white relative flex justify-center items-center gap-2.5"
                      >
                        <img src={`${process.env.PUBLIC_URL}/images/usdt.png`} alt="USDT(BSC)" />
                        <span className="md:text-2xl sm:text-lg text-base text-white font-bold capitalize whitespace-nowrap">
                          USDT(BSC)
                        </span>
                      </span>
                    </div>
                    <div>
                      <h4 className="w-max text-right sm:text-2xl text-lg leading-none text-text font-normal capitalize">
                        Balance : <span className="text-[#FCCB25] font-bold">{usdtBalance}</span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="sm:p-4 p-3 sm:rounded-2xl rounded-lg flex flex-col sm:flex-row gap-2 sm:items-center justify-between bg-[#86d7fe66]">
                  <div>
                    <input
                      type="text"
                      className="w-full bg-transparent xl:text-3xl sm:text-xl placeholder:text-black focus-visible:outline-0"
                      placeholder="0"
                      value={tokenAmount}
                      readOnly
                    />
                  </div>
                  <div>
                    <div className="group relative bg-[#FA9B27] md:px-6 px-4 md:py-4 py-2 rounded-[10px] sm:mb-4 mb-3">
                      <span
                        className="w-max text-[20px] xl:text-2xl tracking-[1.3px] leading-none uppercase text-white relative flex justify-center items-center gap-2.5"
                      >
                        <img src={`${process.env.PUBLIC_URL}/images/bdogito.png`} alt="BDOGITO" />
                        <span className="md:text-2xl sm:text-lg text-base text-white font-bold capitalize whitespace-nowrap">
                          BDOGITO
                        </span>
                      </span>
                    </div>
                    <div>
                      <h4 className="w-max sm:text-2xl text-right text-lg leading-none text-text font-normal capitalize ml-auto">
                        Balance : <span className="text-[#FCCB25] font-bold">{tokenBalance}</span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="w-full 2xl:text-3xl md:text-2xl text-base font-semibold text-white bg-orange sm:p-4 p-3 rounded-full capitalize"
                    onClick={wallet ? (isApproved ? handleSwapTokens : handleApprove) : handleConnectWallet}
                    disabled={connecting || loading || (wallet && isSwapDisabled)}
                  >
                    {connecting
                      ? 'Connecting...'
                      : wallet
                      ? isApproved
                        ? loading
                          ? (
                              <span className="flex items-center justify-center">
                                <img
                                  src={`${process.env.PUBLIC_URL}/images/load.gif`}
                                  alt="Loading"
                                  className="w-6 h-6 mr-2"
                                />
                                Processing...
                              </span>
                            )
                          : 'Swap'
                        : loading
                        ? (
                            <span className="flex items-center justify-center">
                              <img
                                src={`${process.env.PUBLIC_URL}/images/load.gif`}
                                alt="Loading"
                                className="w-6 h-6 mr-2"
                              />
                              Processing...
                            </span>
                          )
                        : 'Approve'
                      : 'Connect wallet'}
                  </button>
                </div>
              </div>
              <div className="mt-6 text-center">
                <button
                  onClick={handleCopyLink}
                  className="w-full 2xl:text-3xl md:text-2xl text-base font-semibold text-white bg-orange sm:p-4 p-3 rounded-full capitalize"
                >
                  Copy Referral Link - 5% USDT
                </button>
              </div>
              {message && (
                <div className="mt-4 message-box p-4 bg-gray-800 text-white text-center rounded">
                  {message}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Bridge;